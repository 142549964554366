import { stepsData } from '@/constants/matcher'
import { cn } from '@/utils/clsx'

interface ProgressDotsProps {
  currentStep: number
  totalSteps: number
}

const ProgressDots: React.FC<ProgressDotsProps> = ({
  currentStep,
  totalSteps,
}) => {
  return (
    <div className='flex items-center gap-1 sm:gap-3'>
      {Array.from({
        length: totalSteps,
      }).map((_, index) => (
        <div
          key={index}
          className={cn('w-full transition-all h-full flex flex-col')}
        >
          <div
            className={cn(
              'hidden whitespace-nowrap md:block',
              index <= currentStep
                ? 'text-on-surface dark:text-dark-on-surface'
                : 'text-on-surface/30 dark:text-dark-on-surface/30'
            )}
          >
            {stepsData[index + 1]?.title}
          </div>
          <div
            className={cn(
              'h-1',
              index <= currentStep
                ? 'line-glow'
                : 'bg-on-surface/30 dark:bg-dark-on-surface/30 rounded-md',
              index === 0
                ? '!saturate-[.4]'
                : index === 1
                  ? '!saturate-[.55]'
                  : index === 2
                    ? '!saturate-[.7]'
                    : index === 3
                      ? '!saturate-[.85]'
                      : index === 4
                        ? '!saturate-[1]'
                        : index === 5
                          ? '!saturate-[1.15]'
                          : index === 6
                            ? '!saturate-[1.3]'
                            : index === 7
                              ? '!saturate-[1.45]'
                              : index === 8
                                ? '!saturate-[1.6]'
                                : index === 9
                                  ? '!saturate-[1.75]'
                                  : ''
            )}
          />
        </div>
      ))}
    </div>
  )
}

export default ProgressDots
