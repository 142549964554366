'use client'

import { RiSparklingFill } from '@remixicon/react'
import { useEffect, useRef, useState } from 'react'

import useThreadState from '@/hooks/context/useThreadState'

import { markdown } from '@/utils'
import { cn } from '@/utils/clsx'
import { applicationGuidanceEnhancePrompt } from '@/utils/prompts'

import LoadingScreen from '../LoadingScreen'
import ProgressButtons from '../ProgressButtons'
import TextAreaWithUpload from '../TextAreaWithUpload'
import lottieFolderCompleted from '../../../../public/lottie/folder_completed.json'

interface BestPatentOptionStepProps {
  getAnswerWrapper: (question: string, streaming: boolean) => Promise<void>
  loading?: boolean
  goBack: () => void
}

const BestPatentOptionStep: React.FC<BestPatentOptionStepProps> = ({
  getAnswerWrapper,
  loading,
  goBack,
}) => {
  const { questions, steps, setCurrentStep, currentStep, setSteps } =
    useThreadState()
  const [numQuestions, setNumQuestions] = useState<number>()
  const [enhancing, setEnhancing] = useState(false)
  const answerRef = useRef<HTMLDivElement>(null)
  const additionalInfoRef = useRef<string>('')

  useEffect(() => {
    if (steps[currentStep]?.numQuestions) {
      setNumQuestions(steps[currentStep].numQuestions ?? 0)
    }
  }, [])

  useEffect(() => {
    if (!loading && questions[questions.length - 1]?.messages[1]?.message) {
      setSteps({
        ...steps,
        [currentStep]: {
          numQuestions: numQuestions ?? 1,
        },
      })
      additionalInfoRef.current = ''
      setEnhancing(false)
    }
  }, [loading, numQuestions])

  const finish = () => {
    setCurrentStep(currentStep + 1)
  }

  const enhance = async (values: { [x: string]: string }) => {
    setEnhancing(true)
    await getAnswerWrapper(
      applicationGuidanceEnhancePrompt(
        questions[questions.length - 1]?.messages[1]?.message ?? '',
        values.input ?? ''
      ),
      true
    )
    setNumQuestions((numQuestions ?? 1) + 1)
  }

  return (
    <div className='flex h-full grow flex-col'>
      <div
        ref={answerRef}
        className='flex h-[calc(100vh-98px)] w-full overflow-y-auto p-2'
      >
        <div
          id='scroller'
          className={cn(
            'm-auto flex w-full min-h-full flex-col rounded-lg bg-surface p-6 text-left text-on-surface sm:max-w-[70em] dark:bg-dark-surface dark:text-dark-on-surface',
            !questions[questions.length - 1]?.messages[1]?.message
              ? 'h-full'
              : ''
          )}
        >
          {questions[questions.length - 1]?.messages[1]?.message ? (
            <>
              <div
                className={cn(
                  'markdown-answer grow break-words text-left text-glow',
                  loading ? '' : 'fade-text-glow-out'
                )}
              >
                {markdown(
                  questions[questions.length - 1]?.messages[1]
                    ?.message as string,
                  loading
                )}
              </div>
              {!loading && (
                <TextAreaWithUpload
                  inputRef={additionalInfoRef}
                  onFinish={enhance}
                  finishIcon={<RiSparklingFill className='size-5' />}
                  finishText='Enhance'
                  formErrorMessage='How would you like to enhance this information?'
                  placeholder='How would you like to enhance this information?'
                  uploadTooltip='Upload files about your idea'
                />
              )}
              <div id='anchor' className='opacity-0'>
                a
              </div>
            </>
          ) : (
            <LoadingScreen
              lottieAnimation={lottieFolderCompleted}
              text={[
                'Retrieving detailed instructions for patent application...',
                'Organizing steps into a clear and actionable guide...',
                'Loading examples of successful patent applications...',
                'Highlighting key requirements and common pitfalls...',
                'Preparing resources for completing the application...',
              ]}
              timeInterval={10000}
              infiniteLoader
            />
          )}
        </div>
      </div>
      <ProgressButtons
        containerRef={answerRef}
        changeIndicator={questions[questions.length - 1]?.messages[1]?.message}
        goBack={goBack}
        goNext={finish}
        disabledBack={
          enhancing ||
          !questions[questions.length - 1]?.messages[1]?.message ||
          loading
        }
        disabledNext={
          enhancing ||
          !questions[questions.length - 1]?.messages[1]?.message ||
          loading
        }
      />
    </div>
  )
}

export default BestPatentOptionStep
