import { sectionToString } from '..'

import { Section, Subsection } from '@/types/document'

export const citePrompt = (section: Subsection | Section) => {
  return `RESPOND IN JSON.

TASK:
Your task is to provide scholarly citations in the body of the narrative text for the following section:

${sectionToString(section)}

List of all references should be added to "references" field of the JSON response and NOT IN THE END OF THE TEXT.

RESPONSE FORMAT:
Return the enhanced section in valid JSON with the following structure:
{
  "title": "Section Title",
  "text": "Section text in markdown with citations in the body of the text. Example: 'This is a sentence with a citation (Name, year).'",
  "references: ["source1", "source2", ...],
  "subsections": [
    {
      "title": "Subsection Title",
      "text": "Subsection text in markdown with citations in the body of the text. Example: 'This is a sentence with a citation (Name, year).'",
      "references: ["source1", "source2", ...]
    }
  ]
}


THERE MUST BE AT LEAST 1 CITATION PER PARAGRAPH.
INCLUDE LIST OF ALL CITATIONS IN THE "references" FIELD of the JSON.
references array can use markdown format for values (example: "references" : ["[Example name. (YYYY) etc.](Example link)", "Example no link source"] ). 
references array source values should be as detailed as possible (example: "references": ["[Department of Transportation. (2025). Infrastructure for Rebuilding America (INFRA) Grant Program](link)"] is better than just "link" or "[Department of Transportation](link)"). It should include the author, year, title, and link if available.
RESPOND IN JSON.
`
}
