import { message } from 'antd'
import { jsonrepair } from 'jsonrepair'
import { Dispatch, SetStateAction } from 'react'

import { createTmpMessage, draftAnswer } from '.'
import { generateUUID } from '..'
import { generateSectionPrompt } from '../prompts/generateSectionPrompt'

import { User } from '@/types'
import { IQuestion } from '@/types/chatbot'
import { Section } from '@/types/document'

const generateSection = async (
  setSections: Dispatch<
    SetStateAction<{
      sections?: Section[]
      history: Section[][]
      historyIndex: number
    }>
  >,
  setSteps: Dispatch<SetStateAction<{ [key: string]: any }>>,
  instructions: string,
  questions: IQuestion[],
  agentId: string,
  currentStep: number,
  selectedConversation?: string,
  user?: User,
  sections?: Section[]
) => {
  const prompt = generateSectionPrompt(instructions, sections)
  const tmpQuestion: IQuestion = createTmpMessage(prompt, agentId)

  let numRetries = 0
  let success = false
  while (numRetries < 3 && !success) {
    try {
      const answer = await draftAnswer(
        prompt,
        [...(questions ?? []), tmpQuestion],
        agentId,
        user,
        selectedConversation,
        true
      )
      const json = JSON.parse(
        jsonrepair(answer.replaceAll('```json', '').replaceAll('```', ''))
      )
      if (!json.title || !json.text) {
        throw new Error('Wrong JSON format returned!')
      }
      setSections(
        ({
          sections: prevSections,
          history: prevHistory,
          historyIndex: prevHistoryIndex,
        }) => {
          const newSections = [
            ...(prevSections ?? []),
            {
              id: generateUUID(),
              title: json.title,
              text: json.text,
              subsections:
                json.subsections?.map(
                  (subsection: { title: string; text: string }) => ({
                    id: generateUUID(),
                    title: subsection.title,
                    text: subsection.text,
                  })
                ) ?? [],
            },
          ]
          const newHistory = [
            ...prevHistory.slice(0, prevHistoryIndex + 1),
            newSections,
          ]
          const newHistoryIndex = newHistory.length - 1
          setSteps((prevSteps) => ({
            ...prevSteps,
            [currentStep]: {
              ...prevSteps[currentStep],
              sections: newSections,
            },
          }))
          return {
            sections: newSections,
            history: newHistory,
            historyIndex: newHistoryIndex,
          }
        }
      )
      success = true
    } catch (error) {
      console.error(error)
    }
    numRetries++
  }
  if (!success) {
    message.error('Failed to generate section. Please try again.')
  }
}

export default generateSection
