import axios from 'axios'
import useSWR from 'swr'

import { configHeader } from '@/constants/api'
import { API_URL } from '@/constants/env'

import { User } from '@/types'

const useAuth = () => {
  const config = {
    method: 'get',
    withCredentials: true,
    ...configHeader,
  }

  const { data, error, isLoading, mutate } = useSWR(
    ['auth'],
    async () => axios(`${API_URL}/auth/me`, config),
    { revalidateOnFocus: false, shouldRetryOnError: false, errorRetryCount: 0 }
  )

  return {
    user: error || !data?.data.email ? undefined : (data?.data as User),
    isLoading,
    error,
    mutate,
  }
}

export default useAuth
