'use client'

import { Button, Modal } from 'antd'
import Image from 'next/image'
import Link from 'next/link'
import { useSearchParams } from 'next/navigation'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import useAuth from '@/hooks/useAuth'

import { logoIndexClx } from '@/branding-config'
import { API_URL, COMPANY } from '@/constants/env'
import { cn } from '@/utils/clsx'

import companyLogo from '../../public/companyLogo.png'

interface LoginModalProps {
  open: boolean
  setOpen: (open: boolean) => void
}

const LoginModal: React.FC<LoginModalProps> = ({ open, setOpen }) => {
  const { t } = useTranslation()
  const { user, mutate } = useAuth()
  const searchParams = useSearchParams()

  useEffect(() => {
    if (user) {
      setOpen(false)
    }
  }, [user])

  const login = () => {
    // check if 'code' is in the query params
    const code = searchParams.get('code')

    const popup = window.open(
      `${API_URL}/auth/login?redirect=''${code ? `&code=${code}` : ''}`,
      '_blank',
      'width=500,height=700,scrollbars=yes'
    )

    const popupCheck = setInterval(() => {
      if (popup?.closed) {
        clearInterval(popupCheck)
        mutate()
      }
    }, 500)
  }

  return (
    <Modal open={open} centered footer={null} onCancel={() => setOpen(false)}>
      <div className='relative flex flex-col gap-8 py-6'>
        <div className='flex items-center justify-center gap-2'>
          <Image
            src={companyLogo}
            alt='company logo'
            className={cn('rounded-full', logoIndexClx)}
          />
          <div className='flex flex-col text-left text-2xl text-on-surface dark:text-dark-on-surface'>
            <span>
              {COMPANY}
              <sup className='-top-0.5 align-super text-[7px] opacity-60'>
                TM
              </sup>
            </span>
          </div>
        </div>
        <section className='flex h-full flex-col items-center justify-between gap-8 px-2 text-center text-on-surface dark:text-dark-on-surface'>
          <p className='text-base'>{t('login-page-instructions')}</p>
          <Button
            type='primary'
            onClick={login}
            className='w-full text-on-surface sm:w-40 dark:text-dark-on-surface'
          >
            {t('login-page-sign-in')}
          </Button>
          <p className='text-xs opacity-85'>
            {t('sign-up-no-account-yet')}{' '}
            <Link
              href='/signup'
              className='font-semibold text-on-surface underline hover:opacity-80 dark:text-dark-on-surface'
            >
              {t('sign-up')}
            </Link>
          </p>
        </section>
      </div>
    </Modal>
  )
}

export default LoginModal
