export const stepsData: { [key: string]: { title: string; tooltip?: string } } =
  {
    '1': {
      title: 'Idea',
    },
    '2': {
      title: 'Company',
    },
    '3': {
      title: 'Studies',
    },
    '4': {
      title: 'Prior art',
    },
    '5': {
      title: 'Innovate',
    },
    '6': {
      title: 'Align',
    },
    '7': {
      title: 'Provisional',
    },
    '8': {
      title: 'Utility',
    },
    '9': {
      title: 'Application',
    },
  }
