import { message } from 'antd'
import { jsonrepair } from 'jsonrepair'
import { Dispatch, SetStateAction } from 'react'

import {
  citePrompt,
  enhanceSectionPrompt,
  enhanceSubsectionPrompt,
} from '@/utils/prompts'

import { createTmpMessage, draftAnswer } from '.'
import { generateUUID } from '..'

import { User } from '@/types'
import { IQuestion } from '@/types/chatbot'
import { Section, Subsection } from '@/types/document'
import { EnhanceType } from '@/types/thread'

const enhance = async (
  setSections: Dispatch<
    SetStateAction<{
      sections?: Section[]
      history: Section[][]
      historyIndex: number
    }>
  >,
  setSteps: Dispatch<SetStateAction<{ [key: string]: any }>>,
  questions: IQuestion[],
  agentId: string,
  section: Section | Subsection,
  type: EnhanceType,
  currentStep: number,
  selectedConversation?: string,
  user?: User,
  sections?: Section[],
  enhanceInstructions?: string,
  showErrorMessage?: boolean,
  addToHistory?: boolean
) => {
  const isSubsection = !('subsections' in section)
  const prompt =
    type === EnhanceType.CITE
      ? citePrompt(section)
      : isSubsection
        ? enhanceSubsectionPrompt(
            section,
            type,
            sections,
            enhanceInstructions,
            currentStep === 8
          )
        : enhanceSectionPrompt(
            section,
            type,
            sections,
            enhanceInstructions,
            currentStep === 8
          )
  const tmpQuestion: IQuestion = createTmpMessage(prompt, agentId)
  const tmpResult: Section | Subsection = {
    ...section,
  }

  let numRetries = 0
  let success = false
  while (numRetries < 3 && !success) {
    try {
      const answer = await draftAnswer(
        prompt,
        [...(questions ?? []), tmpQuestion],
        agentId,
        user,
        selectedConversation,
        true,
        type !== EnhanceType.CITE
      )
      const json = JSON.parse(
        jsonrepair(answer.replaceAll('```json', '').replaceAll('```', ''))
      )
      if (!json.title || !json.text) {
        throw new Error('Wrong JSON format returned!')
      }
      setSections(
        ({
          sections: prevSections,
          history: prevHistory,
          historyIndex: prevHistoryIndex,
        }) => {
          const newSections =
            prevSections?.map((s) => {
              if (isSubsection) {
                const newSubsections =
                  s.subsections?.map((sub) => {
                    if (sub.id === section.id) {
                      return {
                        id: sub.id,
                        title: json.title,
                        text: json.text,
                        references: json.references,
                      }
                    }
                    return sub
                  }) ?? []
                return {
                  ...s,
                  subsections: newSubsections,
                }
              } else {
                if (s.id === section.id) {
                  return {
                    id: s.id,
                    title: json.title,
                    text: json.text,
                    references: json.references,
                    subsections:
                      type === EnhanceType.CITE
                        ? section.subsections
                        : json.subsections?.map(
                            (subsection: {
                              title: string
                              text: string
                              references: string[] | undefined
                            }) => ({
                              id: generateUUID(),
                              title: subsection.title,
                              text: subsection.text,
                              references: subsection.references,
                            })
                          ) ?? [],
                  }
                }
              }
              return s
            }) ?? []
          let newHistory = prevHistory
          let newHistoryIndex = prevHistoryIndex
          if (addToHistory) {
            newHistory = [
              ...prevHistory.slice(0, prevHistoryIndex + 1),
              newSections,
            ]
            newHistoryIndex = newHistory.length - 1
          }
          setSteps((prevSteps) => ({
            ...prevSteps,
            [currentStep]: {
              ...prevSteps[currentStep],
              sections: newSections,
            },
          }))
          return {
            sections: newSections,
            history: newHistory,
            historyIndex: newHistoryIndex,
          }
        }
      )
      success = true
      tmpResult.text = json.text
      tmpResult.title = json.title
      tmpResult.references = json.references
      if ('subsections' in tmpResult) {
        tmpResult.subsections =
          json.subsections?.map(
            (subsection: {
              title: string
              text: string
              references: string[] | undefined
            }) => ({
              id: generateUUID(),
              title: subsection.title,
              text: subsection.text,
              references: subsection.references,
            })
          ) ?? []
      }
    } catch (error) {
      console.error(error)
    }
    numRetries++
  }
  if (!success && showErrorMessage) {
    message.error('Failed to enhance section. Please try again.')
  }

  return tmpResult
}

export default enhance
