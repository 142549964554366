export const feedbackPrompt = (values: { [x: string]: string }) =>
  `These are my replies to the feedback questions you asked me:

What were your expectations for Deepinvent before trying it?
${values.feedback1}

What was your overall impression after trying Deepinvent?
${values.feedback2}

Could you see yourself using it? How?
${values.feedback3}

Any Bugs?
${values.feedback4 ?? '-'}

What are we missing?
${values.feedback5 ?? '-'} 

Any major friction points in the user experience?
${values.feedback6 ?? '-'}     

What do you love about it?
${values.feedback7 ?? '-'}     

What features would you like in the future?
${values.feedback8 ?? '-'}  

What is your profession?
${values.feedback9 ?? '-'}   

What is your company name?
${values.feedback10 ?? '-'}  

Have you filed patents before? How many?
${values.feedback11 ?? '-'}   

Do you work in R&D, Product, or Innovation teams?
${values.feedback12 ?? '-'} 

Tell us a little about your experience with innovation.
${values.feedback13 ?? '-'}      
`
