import axios from 'axios'
import useSWR from 'swr'

import { configHeader } from '@/constants/api'
import { API_URL } from '@/constants/env'

import { Thread } from '@/types/thread'

const useThread = (conversationId?: string) => {
  const config = {
    method: 'get',
    withCredentials: true,
    ...configHeader,
  }

  const { data, error, isLoading, mutate } = useSWR(
    conversationId ? ['thread', conversationId] : null,
    async () => axios(`${API_URL}/thread/${conversationId}`, config),
    {
      revalidateOnFocus: false,
      errorRetryCount: 0,
    }
  )

  if (error) {
    console.error(error)
  }

  return {
    thread: data?.data.error ? undefined : (data?.data as Thread),
    isLoading,
    error,
    mutate,
  }
}

export default useThread
