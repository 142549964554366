'use client'

import { RiSparklingFill } from '@remixicon/react'
import { Input } from 'antd'
import { useEffect, useRef, useState } from 'react'

import useThreadState from '@/hooks/context/useThreadState'

import { markdown } from '@/utils'
import { cn } from '@/utils/clsx'
import { companyEnhancePrompt, companyPrompt } from '@/utils/prompts'
import { scholaryLiteraturePrompt } from '@/utils/prompts/scholaryLiteraturePrompt'

import LoadingScreen from '../LoadingScreen'
import ProgressButtons from '../ProgressButtons'
import TextAreaWithUpload from '../TextAreaWithUpload'
import lottieSearchingWeb from '../../../../public/lottie/searching_web.json'

interface CompanyStepProps {
  getAnswerWrapper: (question: string, streaming: boolean) => Promise<void>
  loading?: boolean
  setLoading: (loading: boolean) => void
  goBack: () => void
}

const CompanyStep: React.FC<CompanyStepProps> = ({
  getAnswerWrapper,
  loading,
  setLoading,
  goBack,
}) => {
  const { questions, steps, setCurrentStep, currentStep, setSteps } =
    useThreadState()
  const [numQuestions, setNumQuestions] = useState<number>()
  const [enhancing, setEnhancing] = useState(false)
  const [companyResearched, setCompanyResearched] = useState(false)
  const answerRef = useRef<HTMLDivElement>(null)
  const additionalInfoRef = useRef<string>('')
  const [companyUrl, setCompanyUrl] = useState<string>()

  useEffect(() => {
    if (steps[currentStep]?.numQuestions) {
      setNumQuestions(steps[currentStep].numQuestions ?? 0)
    }
  }, [])

  useEffect(() => {
    if (steps[currentStep]?.companyResearched) {
      setCompanyResearched(steps[currentStep]?.companyResearched ?? false)
    }
    if (steps[currentStep]?.companyUrl) {
      setCompanyUrl(steps[currentStep].companyUrl ?? '')
    }
  }, [steps[currentStep]])

  const finish = async () => {
    if (companyUrl && companyUrl !== '' && !companyResearched) {
      setSteps((prevSteps) => ({
        ...prevSteps,
        [currentStep]: {
          ...prevSteps[currentStep],
          companyUrl,
          additionalInfo: additionalInfoRef.current,
          numQuestions: (prevSteps[currentStep]?.numQuestions ?? 0) + 1,
        },
      }))
      setLoading(true)
      await getAnswerWrapper(
        companyPrompt(companyUrl, additionalInfoRef.current),
        true
      )
      setCompanyResearched(true)
      setSteps((prevSteps) => ({
        ...prevSteps,
        [currentStep]: {
          ...prevSteps[currentStep],
          companyResearched: true,
        },
      }))
    } else {
      setSteps((prevSteps) => ({
        ...prevSteps,
        [currentStep]: {
          ...prevSteps[currentStep],
          additionalInfo: additionalInfoRef.current,
        },
        [currentStep + 1]: {
          numQuestions: 1,
        },
      }))
      setCurrentStep(currentStep + 1)
      await getAnswerWrapper(
        `Additional information: ${additionalInfoRef.current ?? ''}\n\n${scholaryLiteraturePrompt()}`,
        true
      )
    }
  }

  const enhance = async (values: { [x: string]: string }) => {
    setEnhancing(true)
    await getAnswerWrapper(
      companyEnhancePrompt(
        questions[questions.length - 1]?.messages[1]?.message ?? '',
        values.input ?? ''
      ),
      true
    )
    setNumQuestions((numQuestions ?? 1) + 1)
    setEnhancing(false)
    additionalInfoRef.current = ''
  }

  return (
    <div className='flex h-full grow flex-col'>
      <div
        ref={answerRef}
        className='flex h-[calc(100vh-98px)] w-full overflow-y-auto p-2'
      >
        <div
          id='scroller'
          className={cn(
            'm-auto flex w-full min-h-full flex-col rounded-lg bg-surface p-6 text-left text-on-surface sm:max-w-[70em] dark:bg-dark-surface dark:text-dark-on-surface',
            !questions[questions.length - 1]?.messages[1]?.message
              ? 'h-full'
              : ''
          )}
        >
          {!companyResearched && !loading ? (
            <div className='flex flex-col gap-2'>
              <p>
                Provide your company URL to help Deepinvent understand the
                context in which the idea exists.
              </p>
              <p>
                This company information will help the AI understand your
                industry and goals. It will select the best scientific
                literature and prior art to develop your innovation in the most
                relevant direction.
              </p>
              <Input
                value={companyUrl}
                onChange={(e) => setCompanyUrl(e.target.value)}
                disabled={enhancing}
                placeholder='https://your-company.com'
                className='mt-4'
              />
            </div>
          ) : questions[questions.length - 1]?.messages[1]?.message ? (
            <>
              <div
                className={cn(
                  'markdown-answer grow break-words text-left text-sm sm:text-base text-glow',
                  loading ? '' : 'fade-text-glow-out'
                )}
              >
                {markdown(
                  questions[questions.length - 1]?.messages[1]
                    ?.message as string,
                  loading
                )}
              </div>
              {!loading && (
                <TextAreaWithUpload
                  inputRef={additionalInfoRef}
                  onFinish={enhance}
                  finishIcon={<RiSparklingFill className='size-5' />}
                  finishText='Enhance'
                  formErrorMessage='Add additional information about your company or any other relevant information.'
                  placeholder='How would you like to enhance this information?'
                  uploadTooltip='Upload files about your idea'
                />
              )}
              <div id='anchor' className='opacity-0'>
                a
              </div>
            </>
          ) : (
            <LoadingScreen
              lottieAnimation={lottieSearchingWeb}
              text={[
                'Gathering background information about the company...',
                "Analyzing the company's mission, vision, and values...",
                "Exploring the company's product and service portfolio...",
                "Researching the company's market position and competitors...",
                'Identifying potential innovation areas aligned with the company...',
              ]}
              timeInterval={10000}
              infiniteLoader
            />
          )}
        </div>
      </div>
      <ProgressButtons
        containerRef={answerRef}
        changeIndicator={questions[questions.length - 1]?.messages[1]?.message}
        goBack={goBack}
        goNext={finish}
        disabledBack={
          enhancing ||
          !questions[questions.length - 1]?.messages[1]?.message ||
          loading
        }
        disabledNext={
          enhancing ||
          !questions[questions.length - 1]?.messages[1]?.message ||
          loading
        }
      />
    </div>
  )
}

export default CompanyStep
