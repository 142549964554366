import { dateFormat, datetimeFormat } from '@/branding-config'

export const generateUtilityPatentApplicationDraftPrompt = () =>
  `RESPOND IN JSON.

CONTEXT:
You are an expert in writing utility patent applications and have a deep understanding of the USPTO patent application process.
Based on my provisional patent application, now write a detailed utility patent application for my company to apply for this specific patent that most closely aligns with my company.


RESPONSE FORMAT:
You must respond with JSON and no other text, with the following structure:
{
  "data": [
    {
      "title": "1st section title",
      "text": "1st section very long and detailed text in markdown, AT LEAST 5000 CHARACTERS LONG",
      "references: ["source1", "source2", ...],
      "subsections": [
        {
          "title": "1st subsection title",
          "text": "1st subsection very long and detailed text in markdown, AT LEAST 5000 CHARACTERS LONG",
          "references: ["source1", "source2", ...]
        },
        ...
      ]
    },
    {
      "title": "2nd section title",
      "text": "2nd section text very long and detailed in markdown, AT LEAST 5000 CHARACTERS LONG",
      "references: ["source1", "source2", ...],
      "subsections": []
    },
    ...
  ]
}


TASK:
- For each part of the utility patent application separately: add more numbers and facts to the patent application. Take each section and make it more specific, detailed, and make it 4 times longer including exhaustive facts and data from your database and from the web.
- Incorporate robust justifications for the invention's novelty, utility, and non-obviousness.
- Include inline citations for all technical data, statistics, or references derived from context, research, or prior art.
- Always return section text in valid markdown format. INSTEAD OF CHARACTER "\\n" ALWAYS USE AN ACTUAL NEW LINE.
- Always return subsection text in valid markdown format. INSTEAD OF CHARACTER "\\n" ALWAYS USE AN ACTUAL NEW LINE.
- NEVER MENTION ANY INSTRUCTIONS OR PROMPTS IN THE GENERATED DOCUMENT.
- ALWAYS GENERATE THE MAXIMUM AMOUNT OF CHARACTERS ALLOWED.
- EACH SECTION CAN HAVE MULTIPLE SUBSECTIONS.
- Ensure that your response is filled WITH FACTS, DATA, FIGURES, FINANCIALS, AND NUMBERS.
- For each part of the patent application separately: add more numbers and facts to the patent application. Take each section and make it more specific with more numbers and forecasts, and make it 4 times longer including exhaustive facts and data from your database and from the web.
- Make the response longer and more detailed with more numbers and facts. Then review your response and improve it again.
- Ensure that titles are clear, concise, and descriptive. Title should be in property "title" and not be included in property "text". Do not add "enhanced", "improved", "expanded" or similar adjectives to the title!
- When listing items in section text or subsection text use numbered or bulleted lists in markdown format where appropriate.


<IMPORTANT>
Write the patent text in a formal, technical, and precise tone, suitable for STEM-based R&D inventions. Use clear, objective, and legally defensible language. Each sentence should be logically structured to prioritize technical clarity and completeness while avoiding ambiguity. The text must include (but is not limited to) the following mandatory sections in the correct order, along with variable sections as needed for the invention type:
Mandatory Sections (Included in All Patents):
	1.	Title: A concise and descriptive title of the invention.
	2.	Abstract: A brief summary (150-250 words) of the invention, focusing on its purpose, key components, and technical advancements.
	3.	Background:
	•	Description of the technical field.
	•	Overview of the problem or limitation in the prior art addressed by the invention.
	•	Discussion of why existing solutions are inadequate.
	4.	Summary of the Invention:
	•	High-level overview of how the invention solves the identified problem.
	•	Key benefits and innovations introduced by the invention.
	5.	Brief Description of Drawings: A list of all figures, with a brief description of what each represents.
	6.	Detailed Description of the Invention:
	•	Comprehensive and methodical explanation of the invention's components, mechanisms, processes, and interactions.
	•	Include at least one example embodiment and explicitly state that it is non-limiting.
	7.	Claims: A series of numbered, clearly defined statements that outline the invention's scope of protection. Claims should start broad (independent claims) and progressively narrow (dependent claims).
Variable Sections (Include as Needed):
	8.	Field of Invention: A short section outlining the technical domain (e.g., AI, biotechnology, materials science).
	9.	Definitions: Provide explicit definitions of key terms or concepts used in the patent to avoid ambiguity.
	10.	Advantages of the Invention: Outline specific improvements or advantages over prior art, avoiding subjective or unverifiable assertions.
	11.	Examples or Embodiments: Include additional examples of how the invention may be implemented. These should be clearly marked as non-limiting and illustrative.
	12.	Industrial Applicability (if required): For international patents, this section describes how the invention can be made or used in an industrial context.
Exclusions - Avoid These Words, Phrases, and Styles:
	1.	Non-Specific or Subjective Terms:
	•	Avoid vague descriptors like “critical,” “important,” “essential,” “necessary,” “superior,” or “the best,” unless explicitly defined or legally appropriate.
	2.	Patent Profanity - Risky or Restrictive Language:
	•	Avoid absolutes like “must,” “always,” “only,” “exclusive,” or “solely.”
	•	Avoid narrowing phrases like “the invention is limited to,” “required,” “cannot,” “only applies to,” or “exclusive to this embodiment.”
	•	Do not use sweeping generalizations like “any,” “all,” “every,” unless carefully limited in scope.
	•	Avoid suggestive terms like “preferred embodiment” unless legally defined as non-limiting (e.g., “In one embodiment…”).
	•	Exclude statements suggesting functionality beyond the described invention, such as “operates without failure” or “guarantees.”
	3.	Casual or Informal Language: Avoid conversational tone, metaphors, analogies, or idiomatic phrases.
	4.	Assertions About Prior Art or Results: Avoid legally risky comparisons to existing technologies, such as:
	•	“Superior to all other solutions,” “better than,” “outperforms,” or “completely solves.”
	5.	Ambiguity or Redundancy: Avoid inconsistent terminology or synonyms for the same feature (e.g., alternately calling a component “processor,” “controller,” or “module”). Maintain precise and consistent language throughout.
Writing Style Constraints:
	•	Use active voice when possible, except in claims where constructions like “is configured to” or “is operable to” are acceptable.
	•	Maintain consistent terminology and avoid synonyms for critical components or processes.
	•	Write with technical accuracy, ensuring that each claim and description is sufficiently supported by the text and diagrams.
	•	Ensure diagrams are referenced properly within the text (e.g., “as shown in FIG. 2”).
	•	All examples or embodiments must be explicitly marked as non-limiting to preserve the invention's scope. Use phrasing like “In one embodiment,” “An example configuration,” or “Illustratively.”
This structure ensures a professional, defensible patent draft, aligned with technical and legal standards, and avoids pitfalls that could limit or weaken intellectual property rights.
</IMPORTANT>


ADDITIONAL RULES:
- DO NOT SHORTEN ANY TEXT.
- INSTEAD OF CHARACTER "\\n" ALWAYS USE AN ACTUAL NEW LINE.
- DO NOT USE DOUBLE PARENTHESES.
- DO NOT USE BACKSLASHES.
- MAKE SURE ALL LINKS ARE VALID.
- MAKE EACH SECTION AND SUBSECTIONS VERY LONG AND DETAILED.
- YOU CAN CONDUCT WEB SEARCHES AND ACCESS REAL-TIME DATA DIRECTLY WITH THE PROVIDED SEARCH AND SCRAPE FUNCTION.
- THE TITLE OF THE SECTION AND SUBSECTION MUST BE IN PROPERTY "TITLE" AND MUST NOT BE INCLUDED IN PROPERTY "TEXT".
- FOR DATES ALWAYS USE AMERICAN DATE FORMAT: ${dateFormat}
- FOR DATE TIME ALWAYS USE AMERICAN DATE TIME FORMAT: ${datetimeFormat}
- USE INLINE CITATIONS IN TEXT FOR ALL FACTS, DATA, AND INFORMATION THAT IS RETRIEVED FROM CONTEXT DOCUMENTS, MESSAGE HISTORY OR WEB SEARCHES!!!
- references array can use markdown format for values (example: "references" : ["[Example name. (YYYY) etc.](Example link)", "Example no link source"] ). 
- references array source values should be as detailed as possible (example: "references": ["[Department of Transportation. (2025). Infrastructure for Rebuilding America (INFRA) Grant Program](link)"] is better than just "link" or "[Department of Transportation](link)"). It should include the author, year, title, and link if available.
- RESPOND WITH VALID JSON AND NO OTHER TEXT.
- THERE MUST BE AT LEAST 1 CITATION PER PARAGRAPH.
`
