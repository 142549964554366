'use client'
import {
  RiPauseLine,
  RiPlayLine,
  RiVolumeMuteLine,
  RiVolumeUpLine,
} from '@remixicon/react'
import { FloatButton } from 'antd'
import { useRef, useState } from 'react'

interface VideoBackgroundProps {
  src: string
}

const VideoBackground: React.FC<VideoBackgroundProps> = ({ src }) => {
  const [isMuted, setIsMuted] = useState(true)
  const [isPaused, setIsPaused] = useState(false)
  const vidRef = useRef<HTMLVideoElement>(null)
  return (
    <>
      <video
        ref={vidRef}
        src={src}
        autoPlay
        loop
        playsInline
        muted={isMuted}
        style={{ objectPosition: '25% center' }}
        className='fixed left-1/2 top-1/2 min-h-screen min-w-full -translate-x-1/2 -translate-y-1/2 object-cover brightness-75 sm:!object-center'
      />
      <FloatButton.Group shape='square' className='!bottom-2 !right-2'>
        <FloatButton
          className='opacity-50 hover:opacity-100'
          icon={
            isMuted ? (
              <RiVolumeMuteLine className='size-5' />
            ) : (
              <RiVolumeUpLine className='size-5' />
            )
          }
          onClick={() => setIsMuted(!isMuted)}
        />
        <FloatButton
          className='opacity-50 hover:opacity-100'
          icon={
            isPaused ? (
              <RiPlayLine className='size-5' />
            ) : (
              <RiPauseLine className='size-5' />
            )
          }
          onClick={() => {
            if (vidRef.current?.paused) {
              vidRef.current?.play()
              setIsPaused(false)
            } else {
              vidRef.current?.pause()
              setIsPaused(true)
            }
          }}
        />
      </FloatButton.Group>
    </>
  )
}
export default VideoBackground
