/*
 * https://www.tints.dev/
 * https://m2.material.io/design/color/the-color-system.html#color-theme-creation
 */

import { Metadata } from 'next'

import { APP_URL } from './constants/env'
import { Theme } from './types/theme'

export const defaultTheme: Theme = Theme.DARK

export const colors = {
  light: {
    primary: '#e0e0e0',
    'primary-light': '#ffffff',
    'primary-dark': '#b0b0b0', // Not used anywhere yet but part of material design
    secondary: '#d1c4e9', // primary buttons
    surface: '#ffffff',
    background: '#ece8f0',
    error: '#b00020', // Not used anywhere yet but part of material design
    warning: '#cd9526',
    on: {
      primary: '#212121',
      secondary: '#424242',
      surface: '#212121',
      background: '#333333',
      error: '#f2f2f2', // Not used anywhere yet but part of material design
      warning: '#f2f2f2',
    },
  },
  dark: {
    primary: '#ffffff',
    'primary-light': '#ffffff',
    'primary-dark': '#8C8C8C', // Not used anywhere yet but part of material design
    secondary: '#ffffff', // primary buttons
    surface: '#0e0e11',
    background: '#211f21',
    error: '#b00020', // Not used anywhere yet but part of material design
    warning: '#cd9526',
    on: {
      primary: '#0D0D0D',
      secondary: '#0D0D0D',
      surface: '#ffffff',
      background: '#f2f2f2',
      error: '#f2f2f2', // Not used anywhere yet but part of material design
      warning: '#f2f2f2',
    },
  },
  gray: {
    50: '#E6E6E6',
    100: '#D9D9D9',
    200: '#BFBFBF',
    300: '#A6A6A6',
    400: '#8C8C8C',
    500: '#737373',
    600: '#595959',
    700: '#404040',
    800: '#262626',
    900: '#0D0D0D',
    950: '#000000',
  },
}

export const sidebarTitle: string = ''

// DEVELOPMENT: height: 80, top: 'top-[6.5em]'
// PRODUCTION: height: 60, top: 'top-28'
// export const logoLoginClx = 'h-[100px] w-auto top-[6.5em]'
// export const logoIndexClx = 'h-[42px] lg:h-[80px] w-auto'
// export const logoSidebarClx = 'h-[64px] w-auto'
export const logoLoginClx = 'h-[80px] w-auto top-[6.5em]'
export const logoIndexClx = 'h-[40px] sm:h-[60px] w-auto'
export const logoSidebarClx = 'h-[32px] w-auto'

export const poweredBy = {
  text: 'AIONIC',
  url: 'https://aionicml.com/',
}

export const showIndexHeaderText = true

export const brandMetadata: Metadata = {
  title: `Innovate with Deepinvent`,
  description:
    'Accelerate your patent portfolio with Deepinvent™️ Guide R&D and generate patents in minutes. Find and synthesize scientific research and prior art. Ideate, Iterate, Innovate.',
  openGraph: {
    type: 'website',
    url: 'https://deepinvent.ai',
    title: 'Innovate with Deepinvent',
    description:
      'Accelerate your patent portfolio with Deepinvent™️ Guide R&D and generate patents in minutes. Find and synthesize scientific research and prior art. Ideate, Iterate, Innovate.',
    siteName: 'Innovate with Deepinvent',
    images: [
      {
        url: `${APP_URL}/ogImage.png`,
      },
    ],
  },
  twitter: {
    card: 'summary_large_image',
    site: 'https://deepinvent.ai',
    images: `${APP_URL}/ogImage.png`,
  },
  icons: [{ rel: 'icon', url: '/favicon.ico' }],
}

export const dateFormat = 'MM/DD/YYYY'
export const datetimeFormat = 'MM/DD/YYYY HH:mm'
