'use client'

import { RiSearchLine, RiSparklingFill } from '@remixicon/react'
import { Button, Form, Input, message, Popover } from 'antd'
import { jsonrepair } from 'jsonrepair'
import { SetStateAction, useEffect, useRef, useState } from 'react'

import useThreadState from '@/hooks/context/useThreadState'

import { cn } from '@/utils/clsx'
import { bestPatentPrompt } from '@/utils/prompts'

import SelectInnovationCard from './SelectInnovationCard'
import LoadingScreen from '../LoadingScreen'
import ProgressButtons from '../ProgressButtons'
import lottieCardAnalyzing from '../../../../public/lottie/card_analyzing.json'

import { Innovation } from '@/types/thread'

interface SelectInnovationStepProps {
  getAnswerWrapper: (question: string, streaming: boolean) => Promise<void>
  progressException: boolean
  loading?: boolean
  setLoading: (value: SetStateAction<boolean>) => void
  retry: (additionalInfo: string) => void
  goBack: () => void
  setProgressException: (progressException: boolean) => void
}

const SelectInnovationStep: React.FC<SelectInnovationStepProps> = ({
  getAnswerWrapper,
  loading,
  setLoading,
  progressException,
  retry,
  goBack,
  setProgressException,
}) => {
  const [form] = Form.useForm()
  const { setCurrentStep, currentStep, steps, questions, setSteps } =
    useThreadState()
  const [open, setOpen] = useState(false)
  const [additionalInfo, setAdditionalInfo] = useState('')
  const [selectedInnovation, setSelectedInnovation] = useState<number>()
  const [{ data }, setInnovations] = useState<{
    data: Innovation[]
  }>({
    data: [],
  })
  const answerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!loading) {
      try {
        const innovationsData = JSON.parse(
          jsonrepair(
            (questions[questions.length - 1]?.messages[1]?.message as string)
              .replaceAll('```json', '')
              .replaceAll('```', '')
          )
        )

        const innovations = JSON.parse(
          jsonrepair(innovationsData.data)
        ).innovations

        setInnovations({
          data: innovations,
        })
        if (innovations.length === 0) {
          console.error('No innovations found')
          setProgressException(true)
          return
        }

        setProgressException(false)
        setSteps({
          ...steps,
          [currentStep]: {
            ...steps[currentStep],
            numQuestions: 1,
          },
        })
      } catch (e) {
        console.error(e)
        message.error(
          'Error occurred while analyzing patentable innovations. Please try again.'
        )
        setInnovations({
          data: [],
        })
        setProgressException(true)
      }
    }
  }, [questions[questions.length - 1]?.messages[1]?.message, loading])

  const next = async () => {
    setCurrentStep(currentStep + 1)
    setLoading(true)

    let innovation
    if (data && selectedInnovation !== undefined) {
      innovation = data[selectedInnovation]
    }
    await getAnswerWrapper(bestPatentPrompt(JSON.stringify(innovation)), true)
  }

  return (
    <Form form={form} onFinish={next} autoComplete='off' labelAlign='left'>
      <div
        ref={answerRef}
        className='flex h-[calc(100vh-98px)] w-full overflow-y-auto p-2'
      >
        <div
          id='scroller'
          className={cn(
            'm-auto flex w-full min-h-full flex-col rounded-lg bg-surface p-6 text-left text-on-surface sm:max-w-[70em] dark:bg-dark-surface dark:text-dark-on-surface',
            data.length === 0 || loading ? 'h-auto' : ''
          )}
        >
          {data.length > 0 && !loading ? (
            <>
              <div className='flex h-full grow flex-col gap-6'>
                <div className='markdown-answer grow justify-between break-words text-left'>
                  <div className='flex flex-col gap-4'>
                    <p className='mb-4'>
                      Select one of the patentable innovations listed below. If
                      you don't select one, AI will automatically choose the
                      best fitting patentable innovation for you.
                    </p>
                    {data.map((innovation: Innovation, index: number) => (
                      <SelectInnovationCard
                        key={index}
                        index={index}
                        innovation={innovation}
                        selectedInnovation={selectedInnovation}
                        setSelectedInnovation={setSelectedInnovation}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div id='anchor' className='opacity-0'>
                a
              </div>
            </>
          ) : (
            <LoadingScreen
              lottieAnimation={lottieCardAnalyzing}
              classname='!w-[800px]'
              text={
                progressException
                  ? 'Error occurred while analyzing patentable innovations.'
                  : [
                      'Generating potential innovations...',
                      'Identifying unique and promising ideas...',
                      'Analyzing novelty and patent eligibility...',
                      'Exploring interdisciplinary insights and applications...',
                      'Reviewing prior art and existing patents for relevance...',
                      'Evaluating market demand for innovative concepts...',
                      'Compiling a list of standout innovations...',
                      'Assessing feasibility for patent filing...',
                      'Finalizing recommendations for patenting...',
                      'Preparing a detailed report of findings and suggestions...',
                    ]
              }
              retry={() => retry('')}
              exception={progressException}
              timeInterval={20000}
              infiniteLoader
            />
          )}
        </div>
      </div>
      <ProgressButtons
        containerRef={answerRef}
        changeIndicator={data}
        additionalButtons={
          data.length > 0 &&
          !loading &&
          !progressException && (
            <Popover
              trigger='click'
              className='cursor-pointer'
              overlayClassName='w-full max-w-md'
              onOpenChange={setOpen}
              content={
                <Form
                  autoComplete='off'
                  className='flex w-full flex-col items-end'
                  layout='vertical'
                >
                  <Form.Item
                    name='additionalInfo'
                    rules={[{ type: 'string' }]}
                    className='w-full'
                  >
                    <Input.TextArea
                      rows={5}
                      placeholder='Tell us more about the types of patents you are interested in or any specific instructions to help us find the best matches for you!'
                      onChange={(e) => setAdditionalInfo(e.target.value)}
                      className='w-full'
                    />
                  </Form.Item>
                  <Form.Item className='m-0'>
                    <Button
                      className='flex items-center'
                      htmlType='submit'
                      type='primary'
                      onClick={() => {
                        retry(additionalInfo)
                        setAdditionalInfo('')
                        setOpen(false)
                        setInnovations({
                          data: [],
                        })
                      }}
                      icon={<RiSparklingFill className='size-5' />}
                    >
                      Search again
                    </Button>
                  </Form.Item>
                </Form>
              }
            >
              <Button
                className='flex items-center'
                disabled={open}
                icon={<RiSearchLine className='size-5' />}
              >
                <span className='!hidden sm:!block'>Search again</span>
              </Button>
            </Popover>
          )
        }
        goBack={goBack}
        disabledBack={
          !progressException && (data.length === 0 || loading || open)
        }
        disabledNext={data.length === 0 || loading || progressException || open}
      />
    </Form>
  )
}

export default SelectInnovationStep
