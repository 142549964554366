'use client'

import { RiSparklingFill } from '@remixicon/react'
import { message } from 'antd'
import { useEffect, useRef, useState } from 'react'

import useThreadState from '@/hooks/context/useThreadState'

import { markdown } from '@/utils'
import { cn } from '@/utils/clsx'
import {
  bestPatentEnhancePrompt,
  generateProvisionalPatentApplicationDraftPrompt,
} from '@/utils/prompts'

import LoadingScreen from '../LoadingScreen'
import ProgressButtons from '../ProgressButtons'
import TextAreaWithUpload from '../TextAreaWithUpload'
import lottieDocumentCheck from '../../../../public/lottie/document_check.json'

interface BestPatentOptionStepProps {
  getAnswerWrapper: (
    question: string,
    streaming: boolean,
    json?: boolean
  ) => Promise<void>
  loading?: boolean
  goBack: () => void
}

const BestPatentOptionStep: React.FC<BestPatentOptionStepProps> = ({
  getAnswerWrapper,
  loading,
  goBack,
}) => {
  const {
    questions,
    steps,
    setCurrentStep,
    currentStep,
    setSteps,
    setSections,
    setQuestions,
  } = useThreadState()
  const [numQuestions, setNumQuestions] = useState<number>()
  const [enhancing, setEnhancing] = useState(false)
  const answerRef = useRef<HTMLDivElement>(null)
  const additionalInfoRef = useRef<string>('')

  useEffect(() => {
    if (steps[currentStep]?.numQuestions) {
      setNumQuestions(steps[currentStep].numQuestions ?? 0)
    }
  }, [])

  useEffect(() => {
    if (!loading && questions[questions.length - 1]?.messages[1]?.message) {
      setSteps({
        ...steps,
        [currentStep]: {
          numQuestions: numQuestions ?? 1,
        },
      })
      additionalInfoRef.current = ''
      setEnhancing(false)
    }
  }, [loading, numQuestions])

  const finish = async () => {
    const nextStep = currentStep + 1
    setSteps({
      ...steps,
      [currentStep]: {
        ...steps[currentStep],
        additionalInfo: additionalInfoRef.current,
      },
      [nextStep]: {
        numQuestions: 1,
      },
    })
    setCurrentStep(nextStep)
    try {
      await getAnswerWrapper(
        generateProvisionalPatentApplicationDraftPrompt(
          additionalInfoRef.current
        ),
        false,
        true
      )
    } catch (error) {
      console.error(error)
      message.error(
        'Failed to generate provisional patent application draft. Please try again.'
      )
      setCurrentStep(nextStep - 1)
      setSteps({
        ...steps,
        [nextStep]: undefined,
      })
      setSections({
        sections: [],
        history: [],
        historyIndex: 0,
      })
      setQuestions((prevState) => prevState.slice(0, -1))
    }
  }

  const enhance = async (values: { [x: string]: string }) => {
    setEnhancing(true)
    await getAnswerWrapper(
      bestPatentEnhancePrompt(
        questions[questions.length - 1]?.messages[1]?.message ?? '',
        values.input ?? ''
      ),
      true
    )
    setNumQuestions((numQuestions ?? 1) + 1)
  }

  return (
    <div className='flex h-full grow flex-col'>
      <div
        ref={answerRef}
        className='flex h-[calc(100vh-98px)] w-full overflow-y-auto p-2'
      >
        <div
          id='scroller'
          className={cn(
            'm-auto flex w-full min-h-full flex-col rounded-lg bg-surface p-6 text-left text-on-surface sm:max-w-[70em] dark:bg-dark-surface dark:text-dark-on-surface',
            !questions[questions.length - 1]?.messages[1]?.message
              ? 'h-full'
              : ''
          )}
        >
          {questions[questions.length - 1]?.messages[1]?.message ? (
            <>
              <div
                className={cn(
                  'markdown-answer grow break-words text-left text-sm sm:text-base text-glow',
                  loading ? '' : 'fade-text-glow-out'
                )}
              >
                {markdown(
                  questions[questions.length - 1]?.messages[1]
                    ?.message as string,
                  loading
                )}
              </div>
              {!loading && (
                <TextAreaWithUpload
                  inputRef={additionalInfoRef}
                  onFinish={enhance}
                  finishIcon={<RiSparklingFill className='size-5' />}
                  finishText='Enhance'
                  formErrorMessage='Add additional information about the selected patent.'
                  placeholder='How would you like to enhance this information?'
                  uploadTooltip='Upload files about your idea'
                />
              )}
              <div id='anchor' className='opacity-0'>
                a
              </div>
            </>
          ) : (
            <LoadingScreen
              lottieAnimation={lottieDocumentCheck}
              text={[
                'Reviewing all identified innovations in detail...',
                'Comparing innovations to company goals and strengths...',
                'Evaluating potential market impact and scalability...',
                'Prioritizing ideas based on feasibility and uniqueness...',
                'Selecting the best innovation for patent preparation...',
              ]}
              timeInterval={10000}
              infiniteLoader
            />
          )}
        </div>
      </div>
      <ProgressButtons
        containerRef={answerRef}
        changeIndicator={questions[questions.length - 1]?.messages[1]?.message}
        goBack={goBack}
        goNext={finish}
        disabledBack={
          enhancing ||
          !questions[questions.length - 1]?.messages[1]?.message ||
          loading
        }
        disabledNext={
          enhancing ||
          !questions[questions.length - 1]?.messages[1]?.message ||
          loading
        }
      />
    </div>
  )
}

export default BestPatentOptionStep
