'use client'
import { Form, Input } from 'antd'

import useDrawerState from '@/hooks/context/useDrawerState'
import useThreadState from '@/hooks/context/useThreadState'

import { generateUUID } from '@/utils'
import { feedbackPrompt } from '@/utils/prompts'

import LoadingScreen from '../LoadingScreen'
import ProgressButtons from '../ProgressButtons'
import lottieFolderCompleted from '../../../../public/lottie/folder_completed.json'

interface FeedbackStepProps {
  getAnswerWrapper: (question: string, streaming: boolean) => Promise<void>
  loading?: boolean
  goBack: () => void
}

const FeedbackStep: React.FC<FeedbackStepProps> = ({
  getAnswerWrapper,
  loading,
  goBack,
}) => {
  const [form] = Form.useForm()
  const { steps, currentStep, setSteps } = useThreadState()
  const { setSelectedConversation } = useDrawerState()

  const finish = async (values: { [x: string]: string }) => {
    setSteps({
      ...steps,
      [currentStep]: {
        ...steps[currentStep],
        numQuestions: 1,
        ...values,
      },
    })
    await getAnswerWrapper(feedbackPrompt(values), false)
    setSelectedConversation(generateUUID())
  }

  return (
    <Form
      form={form}
      onFinish={finish}
      autoComplete='off'
      labelAlign='left'
      initialValues={steps[currentStep]}
    >
      <div className='flex h-[calc(100vh-98px)] w-full overflow-y-auto p-2'>
        <div className='m-auto flex min-h-full w-full flex-col rounded-lg bg-surface p-6 text-left text-on-surface sm:max-w-[70em] dark:bg-dark-surface dark:text-dark-on-surface'>
          {loading ? (
            <LoadingScreen
              lottieAnimation={lottieFolderCompleted}
              text='Finalizing patent application...'
              infiniteLoader
            />
          ) : (
            <>
              <p className='pb-4'>
                Thank you for using Deepinvent! To improve your experience, we
                would like to ask a few questions. Your feedback is essential in
                helping us enhance our service.
              </p>

              <div className='flex flex-col gap-2'>
                <div className='pb-2'>
                  What were your expectations for Deepinvent before trying it?
                </div>
                <Form.Item name='feedback1' className='w-full'>
                  <Input.TextArea />
                </Form.Item>
              </div>
              <div className='flex flex-col gap-2'>
                <div className='pb-2'>
                  What was your overall impression after trying Deepinvent?
                </div>
                <Form.Item name='feedback2' className='w-full'>
                  <Input.TextArea />
                </Form.Item>
              </div>
              <div className='flex flex-col gap-2'>
                <div className='pb-2'>
                  Could you see yourself using it? How?
                </div>
                <Form.Item name='feedback3' className='w-full'>
                  <Input.TextArea />
                </Form.Item>
              </div>
              <div className='flex flex-col gap-2'>
                <div className='pb-2'>Any Bugs?</div>
                <Form.Item name='feedback4' className='w-full'>
                  <Input.TextArea />
                </Form.Item>
              </div>
              <div className='flex flex-col gap-2'>
                <div className='pb-2'>What are we missing?</div>
                <Form.Item name='feedback5' className='w-full'>
                  <Input.TextArea />
                </Form.Item>
              </div>
              <div className='flex flex-col gap-2'>
                <div className='pb-2'>
                  Any major friction points in the user experience?
                </div>
                <Form.Item name='feedback6' className='w-full'>
                  <Input.TextArea />
                </Form.Item>
              </div>
              <div className='flex flex-col gap-2'>
                <div className='pb-2'>What do you love about it?</div>
                <Form.Item name='feedback7' className='w-full'>
                  <Input.TextArea />
                </Form.Item>
              </div>
              <div className='flex flex-col gap-2'>
                <div className='pb-2'>
                  What features would you like in the future?
                </div>
                <Form.Item name='feedback8' className='w-full'>
                  <Input.TextArea />
                </Form.Item>
              </div>
              <div className='flex flex-col gap-2'>
                <div className='pb-2'>What is your profession?</div>
                <Form.Item name='feedback9' className='w-full'>
                  <Input.TextArea />
                </Form.Item>
              </div>
              <div className='flex flex-col gap-2'>
                <div className='pb-2'>What is your company name?</div>
                <Form.Item name='feedback10' className='w-full'>
                  <Input.TextArea />
                </Form.Item>
              </div>
              <div className='flex flex-col gap-2'>
                <div className='pb-2'>
                  Have you filed patents before? How many?
                </div>
                <Form.Item name='feedback11' className='w-full'>
                  <Input.TextArea />
                </Form.Item>
              </div>
              <div className='flex flex-col gap-2'>
                <div className='pb-2'>
                  Do you work in R&D, Product, or Innovation teams?
                </div>
                <Form.Item name='feedback12' className='w-full'>
                  <Input.TextArea />
                </Form.Item>
              </div>
              <div className='flex flex-col gap-2'>
                <div className='pb-2'>
                  Tell us a little about your experience with innovation.
                </div>
                <Form.Item name='feedback13' className='w-full'>
                  <Input.TextArea />
                </Form.Item>
              </div>
            </>
          )}
        </div>
      </div>
      <ProgressButtons
        goBack={goBack}
        nextText='Finish'
        disabledNext={loading}
        loadingNext={loading}
      />
    </Form>
  )
}

export default FeedbackStep
