'use client'

import { RiRefreshLine } from '@remixicon/react'
import { Button, Grid, message } from 'antd'
import { useState } from 'react'

import useThreadState from '@/hooks/context/useThreadState'

import {
  applicationGuidancePrompt,
  generateUtilityPatentApplicationDraftPrompt,
} from '@/utils/prompts'

import ProgressButtons from '../ProgressButtons'

interface GoogleDocsViewerProps {
  documentUrl?: string
  setDocumentUrl: (documentUrl?: string) => void
  setGeneratingDocument: (generatingDocument: boolean) => void
  getAnswerWrapper: (
    question: string,
    streaming: boolean,
    json?: boolean
  ) => Promise<void>
  setFinishedSteps: (finishedSteps: number) => void
  setInitialSectionsStatus: (
    initialSectionsStatus?:
      | 'enhancing'
      | 'enhanced'
      | 'enhancingTwice'
      | 'enhancedTwice'
  ) => void
  isUtilityPatent?: boolean
}

const GoogleDocsViewer: React.FC<GoogleDocsViewerProps> = ({
  documentUrl,
  setDocumentUrl,
  setGeneratingDocument,
  getAnswerWrapper,
  setFinishedSteps,
  setInitialSectionsStatus,
  isUtilityPatent,
}) => {
  const {
    setCurrentStep,
    currentStep,
    steps,
    setSteps,
    setSections,
    setQuestions,
  } = useThreadState()
  const [iframeKey, setIframeKey] = useState(false)
  const screens = Grid.useBreakpoint()

  const finish = async () => {
    if (isUtilityPatent) {
      setSteps({
        ...steps,
        [currentStep + 1]: {
          numQuestions: 1,
        },
      })
      setCurrentStep(currentStep + 1)
      await getAnswerWrapper(applicationGuidancePrompt(), true)
    } else {
      const nextStep = currentStep + 1
      setSteps({
        ...steps,
        [nextStep]: {
          numQuestions: 1,
        },
      })
      setCurrentStep(nextStep)
      setFinishedSteps(0)
      setDocumentUrl(undefined)
      setInitialSectionsStatus('enhanced')
      try {
        await getAnswerWrapper(
          generateUtilityPatentApplicationDraftPrompt(),
          false,
          true
        )
      } catch (error) {
        console.error(error)
        message.error(
          'Failed to generate utility patent application draft. Please try again.'
        )
        setCurrentStep(nextStep - 1)
        setSteps({
          ...steps,
          [nextStep]: undefined,
        })
        setSections({
          sections: [],
          history: [],
          historyIndex: 0,
        })
        setQuestions((prevState) => prevState.slice(0, -1))
      }
    }
  }

  return (
    <>
      <div className='m-auto size-full h-[100vh-98px] sm:max-w-[100em]'>
        <iframe
          key={iframeKey.toString()}
          src={documentUrl}
          className='m-auto size-full max-w-[100rem]'
        />
      </div>
      <ProgressButtons
        additionalButtons={
          <Button
            icon={!screens.lg && <RiRefreshLine className='size-5' />}
            onClick={() => setIframeKey(!iframeKey)}
          >
            {screens.lg && 'Refresh editor'}
          </Button>
        }
        goBack={() => {
          setDocumentUrl(undefined)
          setGeneratingDocument(false)
          setSteps({
            ...steps,
            [currentStep]: {
              ...steps[currentStep],
              documentUrl: undefined,
            },
          })
        }}
        goNext={finish}
      />
    </>
  )
}

export default GoogleDocsViewer
