'use client'
import { Form, Input, Radio, Space } from 'antd'

import useDrawerState from '@/hooks/context/useDrawerState'
import useThreadState from '@/hooks/context/useThreadState'

import { generateUUID } from '@/utils'
import { feedbackPrompt } from '@/utils/prompts'

import LoadingScreen from '../LoadingScreen'
import ProgressButtons from '../ProgressButtons'
import lottieFolderCompleted from '../../../../public/lottie/folder_completed.json'

interface FeedbackStepProps {
  getAnswerWrapper: (question: string, streaming: boolean) => Promise<void>
  loading?: boolean
  goBack: () => void
}

const FeedbackStep: React.FC<FeedbackStepProps> = ({
  getAnswerWrapper,
  loading,
  goBack,
}) => {
  const [form] = Form.useForm()
  const { steps, currentStep, setSteps } = useThreadState()
  const { setSelectedConversation } = useDrawerState()

  const finish = async (values: { [x: string]: string }) => {
    setSteps({
      ...steps,
      [currentStep]: {
        ...steps[currentStep],
        numQuestions: 1,
        ...values,
      },
    })
    await getAnswerWrapper(feedbackPrompt(values), false)
    setSelectedConversation(generateUUID())
  }

  return (
    <Form
      form={form}
      onFinish={finish}
      autoComplete='off'
      labelAlign='left'
      initialValues={steps[currentStep]}
    >
      <div className='flex h-[calc(100vh-98px)] w-full overflow-y-auto p-2'>
        <div className='m-auto flex min-h-full w-full flex-col rounded-lg bg-surface p-6 text-left text-on-surface sm:max-w-[70em] dark:bg-dark-surface dark:text-dark-on-surface'>
          {loading ? (
            <LoadingScreen
              lottieAnimation={lottieFolderCompleted}
              text='Finalizing patent application...'
              infiniteLoader
            />
          ) : (
            <>
              <p className='pb-4'>
                Thank you for using Deepinvent! To improve your experience, we
                would like to ask a few questions. Your feedback is essential in
                helping us enhance our service.
              </p>

              <div className='flex flex-col gap-2'>
                <div className='pb-2'>
                  How satisfied are you with the Deepinvent assistance you
                  received?
                </div>
                <div className='items flex flex-col gap-4'>
                  <Form.Item
                    name='feedback1'
                    className='w-full'
                    rules={[
                      { required: true, message: 'Please select an option.' },
                    ]}
                  >
                    <Radio.Group>
                      <Space direction='vertical'>
                        <Radio value='Extremely satisfied'>
                          Extremely satisfied
                        </Radio>
                        <Radio value='Very satisfied'>Very satisfied</Radio>
                        <Radio value='Moderately satisfied'>
                          Moderately satisfied
                        </Radio>
                        <Radio value='Slightly satisfied'>
                          Slightly satisfied
                        </Radio>
                        <Radio value='Not at all satisfied'>
                          Not at all satisfied
                        </Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </div>
              </div>
              <div className='flex flex-col gap-2'>
                <div className='pb-2'>
                  How would you rate the quality of the patent Deepinvent
                  produced?
                </div>
                <Form.Item
                  name='feedback2'
                  className='w-full max-w-screen-sm'
                  rules={[
                    { required: true, message: 'Please select an option.' },
                  ]}
                >
                  <Radio.Group>
                    <Space direction='vertical'>
                      <Radio value='Excellent'>Excellent</Radio>
                      <Radio value='Very good'>Very good</Radio>
                      <Radio value='Good'>Good</Radio>
                      <Radio value='Fair'>Fair</Radio>
                      <Radio value='Poor'>Poor</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </div>
              <div className='flex flex-col gap-2'>
                <div className='pb-2'>
                  Compared to normal non-assisted patent writing, how would you
                  rate the experience of using Deepinvent for writing a patent
                  application?
                </div>
                <Form.Item
                  name='feedback3'
                  className='w-full max-w-screen-sm'
                  rules={[
                    { required: true, message: 'Please select an option.' },
                  ]}
                >
                  <Radio.Group>
                    <Space direction='vertical'>
                      <Radio value='Much better'>Much better</Radio>
                      <Radio value='Somewhat better'>Somewhat better</Radio>
                      <Radio value='About the same'>About the same</Radio>
                      <Radio value='Somewhat worse'>Somewhat worse</Radio>
                      <Radio value='Much worse'>Much worse</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </div>
              <div className='flex flex-col gap-2'>
                <div className='pb-2'>
                  What other comments, concerns, or reflections do you have
                  about Deepinvent?
                </div>
                <Form.Item name='feedback4' className='w-full'>
                  <Input.TextArea
                    placeholder='Provide your comments, concerns, or reflections about Deepinvent'
                    autoSize={{ minRows: 3, maxRows: 5 }}
                  />
                </Form.Item>
              </div>
              <div className='flex flex-col gap-2'>
                <div className='pb-2'>
                  What other questions should be asked of users of Deepinvent
                  during onboarding to provide even better results?
                </div>
                <Form.Item name='feedback5' className='w-full'>
                  <Input.TextArea
                    placeholder='Provide other questions you might have'
                    autoSize={{ minRows: 3, maxRows: 5 }}
                  />
                </Form.Item>
              </div>

              <div className='flex flex-col gap-2'>
                <div className='pb-2'>
                  What were your expectations for Deepinvent before trying it?
                </div>
                <Form.Item name='feedback6' className='w-full'>
                  <Input.TextArea />
                </Form.Item>
              </div>
              <div className='flex flex-col gap-2'>
                <div className='pb-2'>
                  What was your overall impression after trying Deepinvent?
                </div>
                <Form.Item name='feedback7' className='w-full'>
                  <Input.TextArea />
                </Form.Item>
              </div>
              <div className='flex flex-col gap-2'>
                <div className='pb-2'>
                  Could you see yourself using it? How?
                </div>
                <Form.Item name='feedback8' className='w-full'>
                  <Input.TextArea />
                </Form.Item>
              </div>
              <div className='flex flex-col gap-2'>
                <div className='pb-2'>Any Bugs?</div>
                <Form.Item name='feedback9' className='w-full'>
                  <Input.TextArea />
                </Form.Item>
              </div>
              <div className='flex flex-col gap-2'>
                <div className='pb-2'>What are we missing?</div>
                <Form.Item name='feedback10' className='w-full'>
                  <Input.TextArea />
                </Form.Item>
              </div>
              <div className='flex flex-col gap-2'>
                <div className='pb-2'>
                  Any major friction points in the user experience?
                </div>
                <Form.Item name='feedback11' className='w-full'>
                  <Input.TextArea />
                </Form.Item>
              </div>
              <div className='flex flex-col gap-2'>
                <div className='pb-2'>What do you love about it?</div>
                <Form.Item name='feedback12' className='w-full'>
                  <Input.TextArea />
                </Form.Item>
              </div>
              <div className='flex flex-col gap-2'>
                <div className='pb-2'>
                  What features would you like in the future?
                </div>
                <Form.Item name='feedback13' className='w-full'>
                  <Input.TextArea />
                </Form.Item>
              </div>
              <div className='flex flex-col gap-2'>
                <div className='pb-2'>What is your profession?</div>
                <Form.Item name='feedback14' className='w-full'>
                  <Input.TextArea />
                </Form.Item>
              </div>
              <div className='flex flex-col gap-2'>
                <div className='pb-2'>What is your company name?</div>
                <Form.Item name='feedback15' className='w-full'>
                  <Input.TextArea />
                </Form.Item>
              </div>
              <div className='flex flex-col gap-2'>
                <div className='pb-2'>
                  Have you filed patents before? How many?
                </div>
                <Form.Item name='feedback16' className='w-full'>
                  <Input.TextArea />
                </Form.Item>
              </div>
              <div className='flex flex-col gap-2'>
                <div className='pb-2'>
                  Do you work in R&D, Product, or Innovation teams?
                </div>
                <Form.Item name='feedback17' className='w-full'>
                  <Input.TextArea />
                </Form.Item>
              </div>
              <div className='flex flex-col gap-2'>
                <div className='pb-2'>
                  Tell us a little about your experience with innovation.
                </div>
                <Form.Item name='feedback18' className='w-full'>
                  <Input.TextArea />
                </Form.Item>
              </div>
            </>
          )}
        </div>
      </div>
      <ProgressButtons
        goBack={goBack}
        nextText='Finish'
        disabledNext={loading}
        loadingNext={loading}
      />
    </Form>
  )
}

export default FeedbackStep
