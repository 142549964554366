export const scholaryLiteraturePrompt = () =>
  `Research scientific literature related to the concepts with an interdisciplinary approach to find insights relevant from other fields. 
Make sure to take the idea as a whole, and not just focus on the parts. understand how the idea as a whole is greater than the sum of its parts. Please include scholarly citations.

You must include only real links to literature.
Always double check the link, navigate to it and check if it is valid and applicable to the context. If not applicable, find another one.

<TOOL>
USE SEMANTICSCHOLAR TOOL TO RESEARCH SCIENTIFIC LITERATURE. IF YOU CANT FIND ANY, USE SEARCHANDSCRAPE TO SEARCH THE WEB.
</TOOL>`
