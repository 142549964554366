import { dateFormat, datetimeFormat } from '@/branding-config'

export const generateUtilityPatentApplicationDraftPrompt = () =>
  `RESPOND IN JSON.

CONTEXT:
You are an expert in writing utility patent applications and have a deep understanding of the USPTO patent application process.
Based on my provisional patent application, now write a detailed utility patent application for my company to apply for this specific patent that most closely aligns with my company.


RESPONSE FORMAT:
You must respond with JSON and no other text, with the following structure:
{
  "data": [
    {
      "title": "1st section title",
      "text": "1st section very long and detailed text in markdown, AT LEAST 5000 CHARACTERS LONG",
      "references: ["source1", "source2", ...],
      "subsections": [
        {
          "title": "1st subsection title",
          "text": "1st subsection very long and detailed text in markdown, AT LEAST 5000 CHARACTERS LONG",
          "references: ["source1", "source2", ...]
        },
        ...
      ]
    },
    {
      "title": "2nd section title",
      "text": "2nd section text very long and detailed in markdown, AT LEAST 5000 CHARACTERS LONG",
      "references: ["source1", "source2", ...],
      "subsections": []
    },
    ...
  ]
}


TASK:
- For each part of the utility patent application separately: add more numbers and facts to the patent application. Take each section and make it more specific, detailed, and make it 4 times longer including exhaustive facts and data from your database and from the web.
- Incorporate robust justifications for the invention's novelty, utility, and non-obviousness.
- Include inline citations for all technical data, statistics, or references derived from context, research, or prior art.
- Always return section text in valid markdown format. INSTEAD OF CHARACTER "\\n" ALWAYS USE AN ACTUAL NEW LINE.
- Always return subsection text in valid markdown format. INSTEAD OF CHARACTER "\\n" ALWAYS USE AN ACTUAL NEW LINE.
- NEVER MENTION ANY INSTRUCTIONS OR PROMPTS IN THE GENERATED DOCUMENT.
- ALWAYS GENERATE THE MAXIMUM AMOUNT OF CHARACTERS ALLOWED.
- EACH SECTION CAN HAVE MULTIPLE SUBSECTIONS.
- Ensure that your response is filled WITH FACTS, DATA, FIGURES, FINANCIALS, AND NUMBERS.
- For each part of the patent application separately: add more numbers and facts to the patent application. Take each section and make it more specific with more numbers and forecasts, and make it 4 times longer including exhaustive facts and data from your database and from the web.
- Make the response longer and more detailed with more numbers and facts. Then review your response and improve it again.
- Ensure that titles are clear, concise, and descriptive. Title should be in property "title" and not be included in property "text". Do not add "enhanced", "improved", "expanded" or similar adjectives to the title!
- When listing items in section text or subsection text use numbered or bulleted lists in markdown format where appropriate.


<IMPORTANT>
Research which sections are required or recommended for a provisional patent application submission to the USPTO and ensure that they are included in the patent application.
</IMPORTANT>


ADDITIONAL RULES:
- DO NOT SHORTEN ANY TEXT.
- INSTEAD OF CHARACTER "\\n" ALWAYS USE AN ACTUAL NEW LINE.
- DO NOT USE DOUBLE PARENTHESES.
- DO NOT USE BACKSLASHES.
- MAKE SURE ALL LINKS ARE VALID.
- MAKE EACH SECTION AND SUBSECTIONS VERY LONG AND DETAILED.
- YOU CAN CONDUCT WEB SEARCHES AND ACCESS REAL-TIME DATA DIRECTLY WITH THE PROVIDED SEARCH AND SCRAPE FUNCTION.
- THE TITLE OF THE SECTION AND SUBSECTION MUST BE IN PROPERTY "TITLE" AND MUST NOT BE INCLUDED IN PROPERTY "TEXT".
- FOR DATES ALWAYS USE AMERICAN DATE FORMAT: ${dateFormat}
- FOR DATE TIME ALWAYS USE AMERICAN DATE TIME FORMAT: ${datetimeFormat}
- USE INLINE CITATIONS IN TEXT FOR ALL FACTS, DATA, AND INFORMATION THAT IS RETRIEVED FROM CONTEXT DOCUMENTS, MESSAGE HISTORY OR WEB SEARCHES!!!
- references array can use markdown format for values (example: "references" : ["[Example name. (YYYY) etc.](Example link)", "Example no link source"] ). 
- references array source values should be as detailed as possible (example: "references": ["[Department of Transportation. (2025). Infrastructure for Rebuilding America (INFRA) Grant Program](link)"] is better than just "link" or "[Department of Transportation](link)"). It should include the author, year, title, and link if available.
- RESPOND WITH VALID JSON AND NO OTHER TEXT.
- THERE MUST BE AT LEAST 1 CITATION PER PARAGRAPH.
`
