export const priorArtPrompt = () =>
  `Research the relevant prior art in USPTO and Google Patents related to the concepts with the intention of finding patents we can build on using the scientific literature with the transdisciplinary insights.
Please include scholarly citations.

You must include only real links to patents. Make sure the link is to the actual patent with the correct number.
Always double check the link, navigate to it and check if it is valid and applicable to the context. If not applicable, find another one.

In the beginning of your response add a section that summarizes the scientific literature and patents you found in the previous step.
<TOOL>
USE SERPAPI TOOL TO RESEARCH PATENTS DATABASE. IF YOU CANT FIND ANY, USE SEARCHANDSCRAPE TO SEARCH THE WEB.
</TOOL>`
