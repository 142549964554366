export const feedbackPrompt = (values: { [x: string]: string }) =>
  `These are my replies to the feedback questions you asked me:

How satisfied are you with the Deepinvent assistance you received?
${values.feedback1}

How would you rate the quality of the patent Deepinvent produced?
${values.feedback2}

Compared to normal non-assisted patent writing, how would you rate the experience of using Deepinvent for writing a patent application?
${values.feedback3}

What other comments, concerns, or reflections do you have about Deepinvent?
${values.feedback4 ?? '-'}

What other questions should be asked of users of Deepinvent during onboarding to provide even better results?
${values.feedback5 ?? '-'}

What were your expectations for Deepinvent before trying it?
${values.feedback6 ?? '-'}

What was your overall impression after trying Deepinvent?
${values.feedback7 ?? '-'}

Could you see yourself using it? How?
${values.feedback8 ?? '-'}

Any Bugs?
${values.feedback9 ?? '-'}

What are we missing?
${values.feedback10 ?? '-'} 

Any major friction points in the user experience?
${values.feedback11 ?? '-'}     

What do you love about it?
${values.feedback12 ?? '-'}     

What features would you like in the future?
${values.feedback13 ?? '-'}  

What is your profession?
${values.feedback14 ?? '-'}   

What is your company name?
${values.feedback15 ?? '-'}  

Have you filed patents before? How many?
${values.feedback16 ?? '-'}   

Do you work in R&D, Product, or Innovation teams?
${values.feedback17 ?? '-'} 

Tell us a little about your experience with innovation.
${values.feedback18 ?? '-'}      
`
