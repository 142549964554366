'use client'

import { RiMenu2Line } from '@remixicon/react'
import { Button, Drawer, Grid } from 'antd'
import Image from 'next/image'
import Link from 'next/link'
import { useSearchParams } from 'next/navigation'
import companyLogo from 'public/companyLogo.png'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useDrawerState from '@/hooks/context/useDrawerState'
import useSidebarState from '@/hooks/context/useSidebarState'
import useThemeState from '@/hooks/context/useThemeState'
import useTourState from '@/hooks/context/useTourState'
import useAuth from '@/hooks/useAuth'

import { colors, logoIndexClx, showIndexHeaderText } from '@/branding-config'
import { COMPANY } from '@/constants/env'
import { generateUUID } from '@/utils'
import { cn } from '@/utils/clsx'

import LoginModal from './LoginModal'
import ChatSidebarContent from './Sidebar/ChatSidebarContent'

const { useBreakpoint } = Grid

interface ChatLayoutProps extends PropsWithChildren {
  adminChatbot?: boolean
}

const ChatLayout: React.FC<ChatLayoutProps> = ({ adminChatbot, children }) => {
  const { theme } = useThemeState()
  const screens = useBreakpoint()
  const [searchParams] = useSearchParams()
  const [firstLoad, setFirstLoad] = useState(true)
  const { open, close, setSelectedConversation, selectedConversation, show } =
    useDrawerState()
  const { user, isLoading } = useAuth()
  const { t } = useTranslation()
  const { userTourRef4 } = useTourState()
  const [loginModalOpen, setLoginModalOpen] = useState(false)
  const { minimized } = useSidebarState()

  useEffect(() => {
    if (searchParams && firstLoad) {
      setSelectedConversation(searchParams[1])
      setFirstLoad(false)
    }
  }, [searchParams])

  useEffect(() => {
    if (!selectedConversation || !user) {
      setSelectedConversation(generateUUID())
    }
  }, [user])

  if (isLoading) {
    return null
  }

  return (
    <div className='relative flex w-full'>
      {!user ? (
        <div className='absolute z-10 flex w-full items-center justify-between p-3'>
          <Link href='/' className='flex items-center gap-3'>
            <Image
              src={companyLogo}
              alt='company logo'
              className={cn('cursor-pointer rounded-full', logoIndexClx)}
            />
            {showIndexHeaderText && (
              <div className='flex flex-col text-left'>
                <span className='text-base text-on-surface sm:text-2xl dark:text-dark-on-surface'>
                  {COMPANY}
                  <sup className='-top-0.5 align-super text-[5px] opacity-60 sm:text-[7px]'>
                    TM
                  </sup>
                </span>
              </div>
            )}
          </Link>
          <Button
            onClick={() => setLoginModalOpen(true)}
            className='opacity-85'
          >
            {t('sign-in')}
          </Button>
        </div>
      ) : (
        <>
          {screens.md ? (
            <div
              className={cn(
                'transition-all z-10 bg-surface/80 text-on-surface dark:bg-dark-surface/80 dark:text-dark-on-surface w-16 max-w-[400px]',
                minimized ? 'w-16' : '!w-[400px]'
              )}
            >
              <ChatSidebarContent adminChatbot={adminChatbot} />
            </div>
          ) : (
            <>
              <Drawer
                placement='left'
                closable={false}
                onClose={close}
                open={open}
                styles={{
                  body: {
                    backgroundColor: colors[theme].surface,
                    padding: 0,
                  },
                }}
              >
                <ChatSidebarContent adminChatbot={adminChatbot} isDrawer />
              </Drawer>
              <Button
                ref={userTourRef4}
                className='!absolute top-0 z-10 m-2'
                icon={<RiMenu2Line className='size-5' />}
                onClick={show}
              />
            </>
          )}
        </>
      )}
      <div className='w-full'>{children}</div>
      <LoginModal open={loginModalOpen} setOpen={setLoginModalOpen} />
    </div>
  )
}

export default ChatLayout
