'use client'

import { RiSendPlane2Line, RiSparkling2Fill } from '@remixicon/react'
import { Button, Grid } from 'antd'
import Image from 'next/image'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useDrawerState from '@/hooks/context/useDrawerState'
// import useThemeState from '@/hooks/context/useThemeState'
import useThreadState from '@/hooks/context/useThreadState'
import useAgents from '@/hooks/useAgents'
import useAuth from '@/hooks/useAuth'

import FeedbackStep from '@/components/Matcher/Steps/FeedbackStep'
import PriorArtStep from '@/components/Matcher/Steps/PriorArtStep'
import StudiesStep from '@/components/Matcher/Steps/StudiesStep'

import { stepsData } from '@/constants/matcher'
import { getAnswer, getDrafts, stopAnswering } from '@/service/Chatbot'
import { generateUUID } from '@/utils'
import { ideaPrompt } from '@/utils/prompts'

import DraftEditor from './DraftEditor'
import StepHeader from './StepHeader'
import ApplicationGuidanceStep from './Steps/ApplicationGuidanceStep'
import BestPatentOptionStep from './Steps/BestPatentOptionStep'
import CompanyStep from './Steps/CompanyStep'
import IdeaStep from './Steps/IdeaStep'
import SelectInnovationStep from './Steps/SelectInnovationStep'
import TextAreaWithUpload from './TextAreaWithUpload'
import LoginModal from '../LoginModal'
import VideoBackground from '../VideoBackground'
// import aionicDarkGif from '../../../public/aionic_dark.gif'
// import aionicLightGif from '../../../public/aionic_light.gif'
import companyLogo from '../../../public/companyLogo.png'

import { IQuestion } from '@/types/chatbot'
// import { Theme } from '@/types/theme'

interface MatcherProps {
  ideaRef: React.MutableRefObject<string>
}

const Matcher: React.FC<MatcherProps> = ({ ideaRef }) => {
  const screens = Grid.useBreakpoint()
  // const { theme } = useThemeState()
  const { agents } = useAgents()
  const { user } = useAuth()
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const [progressException, setProgressException] = useState(false)
  const { selectedConversation, setSelectedConversation, mutateConversations } =
    useDrawerState()
  const {
    currentStep,
    questions,
    steps,
    setQuestions,
    setCurrentStep,
    setSections,
    setSteps,
  } = useThreadState()
  const [loginModalOpen, setLoginModalOpen] = useState(false)

  useEffect(() => {
    stopAnswering(true, setLoading)
  }, [selectedConversation])

  useEffect(() => {
    if (!loading && currentStep === 1) {
      mutateConversations()
    }
  }, [loading])

  const getInnovations = async (
    additionalInfo?: string,
    tmpQuestions?: IQuestion[]
  ) => {
    setLoading(true)
    try {
      await getDraftsWrapper(additionalInfo, tmpQuestions)
    } catch (e) {
      setProgressException(true)
      setLoading(false)
    }
  }

  const getDraftsWrapper = async (
    question?: string,
    tmpQuestions?: IQuestion[]
  ) => {
    if (!agents || !selectedConversation) return
    await getDrafts(
      true,
      question && question !== '' ? question : ' ',
      tmpQuestions ?? questions,
      setLoading,
      setQuestions,
      t,
      selectedConversation,
      user?.email
    )
  }

  const retry = async (additionalInfo: string) => {
    setProgressException(false)
    let newQuestions = questions
    if (steps[currentStep]?.numQuestions) {
      newQuestions = questions.slice(0, -steps[currentStep].numQuestions)
    }
    setQuestions(newQuestions)
    await getInnovations(additionalInfo, newQuestions)
  }

  const getAnswerWrapper = async (
    question: string,
    streaming: boolean,
    json?: boolean
  ) => {
    if (!agents || !setQuestions) return
    await getAnswer(
      true,
      question,
      questions,
      agents,
      setLoading,
      setQuestions,
      t,
      false,
      user?.email,
      undefined,
      undefined,
      selectedConversation,
      streaming,
      false,
      false,
      undefined,
      json
    )
  }

  const goBack = () => {
    if (steps[currentStep]?.numQuestions) {
      setQuestions(questions.slice(0, -steps[currentStep].numQuestions))
    }
    setLoading(false)
    setSteps({
      ...steps,
      [currentStep]: undefined,
    })

    if (currentStep === 1) {
      setCurrentStep(0)
      setProgressException(false)
      setQuestions([])
      setSelectedConversation(generateUUID())
    } else {
      if (currentStep === 5) {
        setProgressException(false)
        if (steps[currentStep].previousStep) {
          setCurrentStep(steps[currentStep].previousStep)
          return
        }
      } else if (currentStep === 7) {
        setSections({
          sections: [],
          history: [],
          historyIndex: 0,
        })
      }
      setCurrentStep(currentStep - 1)
    }
  }

  const beginWithIdea = async (values: { [x: string]: string }) => {
    setCurrentStep(1)
    await getAnswerWrapper(ideaPrompt(values.idea ?? ideaRef.current), true)
  }

  if (!user) {
    return (
      <>
        {screens.lg ? (
          <>
            <div className='mx-auto flex size-full h-screen w-full max-w-screen-sm flex-col items-center justify-end gap-4 p-8'>
              <video
                src='/deepinvent.mp4'
                autoPlay
                loop
                playsInline
                muted
                className='absolute left-1/2 top-1/4 h-screen min-w-full -translate-x-1/2 -translate-y-1/4 object-cover brightness-75'
              />
              <Button
                size='large'
                onClick={() => setLoginModalOpen(true)}
                className='btn-glow hover:after:bg-transparent btn-glow z-[1] flex h-14 w-full cursor-pointer items-center justify-between gap-2 rounded-lg px-4 after:!bg-surface after:!text-on-surface hover:after:opacity-0 dark:after:!bg-dark-surface dark:after:!text-dark-on-surface'
              >
                <div className='flex items-center gap-3'>
                  <RiSparkling2Fill className='size-7' />
                  <div className='text-xl'>Invent with AI</div>
                </div>
                <Image
                  src={companyLogo}
                  alt='Deepinvent logo'
                  className='size-7'
                />
              </Button>
            </div>
          </>
        ) : (
          <>
            <VideoBackground src='/deepinvent_no_text.mp4' />
            <div className='flex size-full h-screen flex-col items-center justify-center gap-8 px-2 sm:gap-6'>
              <div className='z-10 flex flex-col text-on-background sm:gap-2 dark:text-dark-on-background'>
                <h1 className='text-2xl sm:text-5xl'>
                  {t('index-title-quote')}
                </h1>
                <h3 className='text-xl brightness-90 sm:text-3xl'>
                  {t('index-title')}
                </h3>
              </div>
              <Button
                size='large'
                onClick={() => setLoginModalOpen(true)}
                className='btn-glow hover:after:bg-transparent btn-glow z-[1] flex h-14 w-full cursor-pointer items-center justify-between gap-2 rounded-lg px-4 after:!bg-surface after:!text-on-surface hover:after:opacity-0 dark:after:!bg-dark-surface dark:after:!text-dark-on-surface'
              >
                <div className='flex items-center gap-3'>
                  <RiSparkling2Fill className='size-7' />
                  <div className='text-xl'>Invent with AI</div>
                </div>
                <Image
                  src={companyLogo}
                  alt='Deepinvent logo'
                  className='size-7'
                />
              </Button>
            </div>
          </>
        )}
        <LoginModal open={loginModalOpen} setOpen={setLoginModalOpen} />
      </>
    )
  }

  if (currentStep !== 0 && !!steps) {
    return (
      <div className='flex size-full flex-col'>
        <StepHeader title={stepsData[currentStep]?.title} />
        {currentStep === 1 ? (
          <IdeaStep
            getAnswerWrapper={getAnswerWrapper}
            loading={loading}
            goBack={goBack}
          />
        ) : currentStep === 2 ? (
          <CompanyStep
            getAnswerWrapper={getAnswerWrapper}
            loading={loading}
            setLoading={setLoading}
            goBack={goBack}
          />
        ) : currentStep === 3 ? (
          <StudiesStep
            getAnswerWrapper={getAnswerWrapper}
            loading={loading}
            goBack={goBack}
          />
        ) : currentStep === 4 ? (
          <PriorArtStep
            getInnovations={getInnovations}
            loading={loading}
            goBack={goBack}
          />
        ) : currentStep === 5 ? (
          <SelectInnovationStep
            getAnswerWrapper={getAnswerWrapper}
            loading={loading}
            setLoading={setLoading}
            retry={retry}
            progressException={progressException}
            setProgressException={setProgressException}
            goBack={goBack}
          />
        ) : currentStep === 6 ? (
          <BestPatentOptionStep
            getAnswerWrapper={getAnswerWrapper}
            loading={loading}
            goBack={goBack}
          />
        ) : currentStep === 7 ? (
          <DraftEditor
            loading={loading}
            goBack={goBack}
            getAnswerWrapper={getAnswerWrapper}
          />
        ) : currentStep === 8 ? (
          <DraftEditor
            loading={loading}
            goBack={goBack}
            getAnswerWrapper={getAnswerWrapper}
            isUtilityPatent
          />
        ) : currentStep === 9 ? (
          <ApplicationGuidanceStep
            loading={loading}
            goBack={goBack}
            getAnswerWrapper={getAnswerWrapper}
          />
        ) : currentStep === 10 ? (
          <FeedbackStep
            getAnswerWrapper={getAnswerWrapper}
            loading={loading}
            goBack={goBack}
          />
        ) : null}
      </div>
    )
  }

  return (
    <>
      <VideoBackground src='/deepinvent_no_text.mp4' />
      <div className='flex size-full h-screen flex-col items-center justify-center gap-4 px-2 sm:gap-6'>
        <div className='z-10 flex flex-col text-on-background sm:gap-2 dark:text-dark-on-background'>
          <h1 className='text-2xl sm:text-5xl'>{t('index-title-quote')}</h1>
          <h3 className='text-xl brightness-90 sm:text-3xl'>
            {t('index-title')}
          </h3>
        </div>
        <div className='z-10 w-full max-w-screen-md'>
          <TextAreaWithUpload
            inputRef={ideaRef}
            onFinish={beginWithIdea}
            finishTooltip='Innovate with AI'
            finishIcon={<RiSendPlane2Line className='size-5' />}
            finishType='primary'
            formErrorMessage='What is your idea?'
            placeholder='What is your idea?'
            uploadTooltip='Upload files about your idea'
            classname='max-w-screen-md'
          />
        </div>
      </div>
      <LoginModal open={loginModalOpen} setOpen={setLoginModalOpen} />
    </>
  )
}

export default Matcher
