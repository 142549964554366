'use client'

import { RiInformationLine } from '@remixicon/react'
import { Tooltip } from 'antd'

import useThreadState from '@/hooks/context/useThreadState'

import ProgressDots from '@/components/ProgressDots'

import { cn } from '@/utils/clsx'

interface StepHeaderProps {
  title?: string
  description?: string
  tooltip?: string
}

const StepHeader: React.FC<StepHeaderProps> = ({
  title,
  description,
  tooltip,
}) => {
  const { currentStep } = useThreadState()

  return (
    <div className='flex w-full flex-col bg-surface px-4 py-2.5 pl-12 text-left text-on-surface shadow-md md:pl-4 dark:bg-dark-surface dark:text-dark-on-surface'>
      <div
        className={cn(
          'flex sm:items-center gap-2 w-full flex-col sm:flex-row md:hidden',
          title || tooltip ? 'justify-between' : 'justify-end'
        )}
      >
        <div className='flex items-center gap-2 text-left'>
          {title && <h2 className='truncate text-lg font-bold'>{title}</h2>}
          {tooltip && (
            <Tooltip title={tooltip}>
              <RiInformationLine className='size-5' />
            </Tooltip>
          )}
        </div>
      </div>
      {description && <p className='text-sm'>{description}</p>}

      <ProgressDots currentStep={currentStep - 1} totalSteps={9} />
    </div>
  )
}

export default StepHeader
